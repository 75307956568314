import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useLocation } from 'react-use';

import {
  selectLogin,
  selectLoginErrors,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  buildErrorNotification,
  getUniqErrorMessages,
} from '@savgroup-front-common/core/src/helpers';
import { signIn } from 'myaccount/domains/Login/loginActions';

import AppRoutes from '../AppRoutes';

import { useAuth } from './AuthProviderContext';
import LoadingScreen from './LoadingScreen';

const CheckAuthorized = () => {
  const errors = useSelector(selectLoginErrors);
  const { pathname, search } = useLocation();
  const authenticatedUser = useSelector(selectLogin);

  const { user, isAuthenticated, login } = useAuth();

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (user && isAuthenticated) {
      dispatch(signIn() as any);

      return;
    }

    setTimeout(() =>
      login({
        state: {
          redirectUrl: `${pathname}${search}`,
        },
      }),
    );
  }, [dispatch, isAuthenticated, login, pathname, search, user]);

  const notifications = getUniqErrorMessages(errors || []).map(
    buildErrorNotification,
  );

  if (!user || !isAuthenticated) {
    return <LoadingScreen notifications={notifications} />;
  }

  datadogRum.setUser({
    id: authenticatedUser.userId,
    name: `${authenticatedUser.firstname} ${authenticatedUser.lastname}`,
    email: authenticatedUser.mail,
    accesses: authenticatedUser.accesses,
    permissions: authenticatedUser.permissions,
  });

  return <AppRoutes />;
};

export default CheckAuthorized;
