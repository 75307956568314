import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { CURRENCIES, REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';
import { SolutionSummaryDto } from 'myaccount/types/SolutionSummaryDto';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';

import useGetClaimGroupSummary, {
  USE_GET_CLAIMS_GROUP_SUMMARY,
} from '../hooks/useGetClaimGroupSummary';
import { useGetSolutionsByClaim } from '../hooks/useGetSolutionsByClaim';
import { IrshStepValues } from '../IrshPages.types';

import ClaimGroupSolutionSchema from './NewClaimGroupSolutionPage.schema';
import { ClaimGroupSolutionValues } from './NewClaimGroupSolutionPage.types';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupSolutionPage = ({ onNextStep }: Args) => {
  const { pushErrors, removeAllNotifications } = useToasts();
  const queryClient = useQueryClient();
  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const {
    mutateAsync: handleSolutionForClaim,
    isLoading: isLoadingSolutionForClaim,
  } = useMutation(
    ['setSolutionForClaim'],
    async ({
      claimId,
      solutionTypeId,
      solutionPrice,
    }: {
      claimId: string;
      solutionTypeId: string;
      solutionPrice?: { amount: string; currency: CURRENCIES };
    }) => {
      return await ClaimService.setClaimSolutionCommand({
        claimId,
        solutionTypeId,
        solutionPrice,
      });
    },
  );

  const { mutateAsync: handleGetHandling, isLoading: isLoadingGetHandling } =
    useMutation(
      ['getHandling'],
      async ({ claimGroupId }: { claimGroupId: string }) => {
        if (!claimGroupId) {
          return undefined;
        }

        removeAllNotifications();
        const responseHandling = await ClaimService.getHandlingByClaimGroup({
          claimGroupId,
        });

        if (!responseHandling || responseHandling.failure) {
          pushErrors(responseHandling?.errors as ErrorFromBack[]);

          return undefined;
        }

        return responseHandling.value;
      },
    );

  const handleSubmit = useCallback(
    async (solutionValue: SolutionSummaryDto) => {
      removeAllNotifications();

      if (!claims) {
        throw new Error('Claims are not defined');
      }
      if (!claimGroupId) {
        throw new Error('ClaimGroupId is not defined');
      }
      if (!solutionValue) {
        return undefined;
      }

      try {
        const responses = await Promise.all(
          claims.map(async (claim) => {
            return handleSolutionForClaim({
              claimId: claim.claimId,
              solutionTypeId: solutionValue?.solutionTypeId,
              solutionPrice: solutionValue?.price
                ? {
                    amount: solutionValue.price.toString(),
                    currency: solutionValue?.priceCurrencyCode as CURRENCIES,
                  }
                : undefined,
            });
          }),
        );

        if (responses.some((res) => res.failure)) {
          const errors = responses
            .filter((res) => res.failure)
            .map((res) => res.errors);

          pushErrors(errors.flat());

          return undefined;
        }

        const responseHandling = await handleGetHandling({
          claimGroupId,
        });

        if (!responseHandling) {
          return undefined;
        }

        await queryClient.invalidateQueries([
          USE_GET_CLAIMS_GROUP_SUMMARY,
          { claimGroupId },
        ]);

        onNextStep({
          newValues: {
            solution: solutionValue,
            handling: responseHandling,
            handlingDeposit:
              values?.solution !== solutionValue
                ? undefined
                : values?.handlingDeposit,
            handlingDelivery:
              values?.solution !== solutionValue
                ? undefined
                : values?.handlingDelivery,
          },
        });

        return undefined;
      } catch (err: any) {
        pushErrors(err);

        return undefined;
      }
    },
    [
      claimGroupId,
      claims,
      handleGetHandling,
      handleSolutionForClaim,
      onNextStep,
      pushErrors,
      queryClient,
      removeAllNotifications,
      values?.solution,
      values?.handlingDeposit,
      values?.handlingDelivery,
    ],
  );

  const { mutateAsync: handleAutoSetSolution, isLoading: isSkippingSolution } =
    useMutation(
      async ({ solution }: { solution: SolutionSummaryDto }) => {
        removeAllNotifications();

        if (!claims) {
          throw new Error('Claims are not defined');
        }
        if (!claimGroupId) {
          throw new Error('ClaimGroupId is not defined');
        }

        try {
          const responses = await Promise.all(
            claims.map(async (claim) => {
              return handleSolutionForClaim({
                claimId: claim.claimId,
                solutionTypeId: solution?.solutionTypeId,
                solutionPrice: solution?.price
                  ? {
                      amount: solution.price.toString(),
                      currency: solution?.priceCurrencyCode as CURRENCIES,
                    }
                  : undefined,
              });
            }),
          );

          if (responses.some((res) => res.failure)) {
            throw new Error('One of the solutions is not being able to set.');
          }

          const responseHandling = await handleGetHandling({
            claimGroupId,
          });

          if (!responseHandling) {
            return undefined;
          }

          await queryClient.invalidateQueries([
            USE_GET_CLAIMS_GROUP_SUMMARY,
            { claimGroupId },
          ]);

          onNextStep({
            newValues: {
              solution: solution,
              handling: responseHandling,
            },
          });
        } catch (err: any) {
          pushErrors(err);

          throw new Error(err);
        }

        return undefined;
      },
      {
        onError: () => {
          onValuesUpdate({
            newValues: {
              solution: {
                solutionTypeId: '',
              } as any,
            },
          });
        },
      },
    );

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
    suspense: true,
  });

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const formContext = useForm<ClaimGroupSolutionValues>({
    resolver: yupResolver(ClaimGroupSolutionSchema),
    defaultValues: {
      chooseSolution: claims?.at(0)?.solutionTypeId,
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const onSubmit = useCallback(
    async ({ chooseSolution }: ClaimGroupSolutionValues) => {
      if (!claims) {
        throw new Error('Claims are not defined');
      }

      const solutionValue = solutions?.find(
        (solution) => solution.solutionTypeId === chooseSolution,
      );

      if (solutionValue) {
        removeAllNotifications();

        await handleSubmit(solutionValue);

        return undefined;
      }

      return undefined;
    },
    [claims, handleSubmit, removeAllNotifications, solutions],
  );

  const firstSolution = solutions?.at(0);

  useEffect(() => {
    if (
      solutions?.length === 1 &&
      values?.solution?.solutionTypeId === undefined &&
      firstSolution &&
      !isSkippingSolution
    ) {
      onValuesUpdate({
        newValues: {
          solution: {
            solutionTypeId: '',
          } as any,
        },
      });

      handleAutoSetSolution({
        solution: firstSolution,
      });
    }
  }, [
    isSkippingSolution,
    solutions?.length,
    firstSolution,
    values?.solution?.solutionTypeId,
    handleAutoSetSolution,
    onValuesUpdate,
  ]);

  return {
    solutions,
    claimGroupConfirmation,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingSolutionForClaim || isLoadingGetHandling,
    isSkippingSolution,
    hasOneSolution: solutions?.length === 1,
  };
};

export default useClaimGroupSolutionPage;
