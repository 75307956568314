import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { userManager } from '@savgroup-front-common/core/src/services/userManager';

import { signIn } from '../../../domains/Login/loginActions';

import { useAuth } from './AuthProviderContext';
import LoadingScreen from './LoadingScreen';

const useOAuthServer = () => {
  const search = new URLSearchParams(window.location.search);
  const routeParam = search.get('redirectUri') ?? '/';
  const state = search.get('state');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        if (!user && state) {
          await userManager.signinRedirectCallback();

          return;
        } else if (!user && !state) {
          await userManager.signinRedirect({
            state: {
              redirectUrl: routeParam || '/',
            },
          });

          return;
        }

        const urlBeforeRedirection =
          (user.state as { redirectUrl?: string })?.redirectUrl || routeParam;

        if (urlBeforeRedirection && !urlBeforeRedirection?.startsWith('/')) {
          throw new Error('Invalid redirect URL');
        }

        signIn()(dispatch);

        navigate(urlBeforeRedirection ?? '/');
      } catch (e) {
        logError(e);

        navigate('/');
      }
    })();
  }, [dispatch, navigate, routeParam, state, user]);
};

export const Callback: React.FC = () => {
  useOAuthServer();

  return <LoadingScreen notifications={[]} />;
};
